.container {
  display: flex;
  width: 500px;
  min-height: 200px;
  text-align: center;
}

.contentContainer {
  flex: 1;
}

.title {
  padding: 0.5rem;
}

.message {
  padding: 1rem .5rem;
}

.footer {
  width: 100%
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.xIcon {
  align-self: end;
  cursor: pointer;
}

.checkmarkIcon {
  margin-bottom: 24px;
  color: var(--arc-color-icon-success);
}

.successMessage {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 10px;
}

.thankYouMessage {
  font-size: 14px;
  margin-bottom: 60px;
}
