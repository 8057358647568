.container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.container:hover {
  background-color: rgba(var(--color-blue-50), 0.25);
}

.containerContent {
  display: flex;

  flex: 1;

  padding: .7rem 0.1em;
  font-size: var(--arc-font-size-14);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
