@import "../../scss/inputs.scss";

.reportForm {
  display: flex;
  flex-direction: column;
  padding: 0 2em;
}

.section {
  display: flex;
  flex-direction: column;
}

.sectionLabel {
  font-weight: 500;
  margin-top: 0.5em;
}

.reportForm input[type="radio"] {
  width: auto;
  margin: 0px .5rem 15px 2rem;
}

.filePickerContainer {
  margin: .5rem 0;
}

.reportForm textarea {
  margin-top: var(--arc-space-0-5);
  min-height: 2.5rem;
  resize: vertical;
}

.submitButton {
  margin-top: 1em;
  align-self: center;
}
